import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import { windowLock } from "../../utils/loader"
import readXlsxFile from "read-excel-file"
import { RecodeMetroType } from "../../components/UpdateStoksMetro/types"
import { metroShemaRecode } from "../../components/UpdateStoksMetro/const"
import { STATUS } from "../../utils/const"

type initialStateType = {
  wildberries: {
    lastStoksUpdated: string
    lastPricesUpdated: string
    status: STATUS
    isCurrentDataUploaded: boolean
  }
}

const initialState: initialStateType = {
  wildberries: {
    lastStoksUpdated: "",
    lastPricesUpdated: "",
    status: STATUS.SUCCESS,
    isCurrentDataUploaded: false,
  },
}

const updateStoksMetroSlice = createSlice({
  name: "updateStoksMetro",
  initialState,
  reducers: {
    setUpdateStoksMetroState: (
      state,
      action: PayloadAction<initialStateType>,
    ) => {
      state.wildberries = action.payload.wildberries
    },
  },
})

export const selectUpdateStoksMetroStore = (state: RootState) =>
  state.updateStoksMetro

export default updateStoksMetroSlice.reducer

export const { setUpdateStoksMetroState } = updateStoksMetroSlice.actions

export const metroUpdateStoks = createAsyncThunk(
  "metroUpdateStoks",
  (
    props: {
      selectedStorageId: string
      updatePrices: boolean
      updateStocks: boolean
    },
    { dispatch },
  ) => {
    const { selectedStorageId, updatePrices, updateStocks } = props
    BaseApi.metroUpdateStoks(selectedStorageId, updatePrices, updateStocks)
      .then((response) => {
        if (response.data) {
          response.data?.state &&
            dispatch(setUpdateStoksMetroState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        }
      })
      .catch((error) => {
        error?.response?.data?.state &&
          dispatch(setUpdateStoksMetroState(error.response.data.state))
      })
  },
)

export const updateMetroState = createAsyncThunk(
  "updateMetroState",
  (_, { dispatch }) => {
    BaseApi.metroUpdateStoksState()
      .then((response) => {
        if (response.data) {
          dispatch(setUpdateStoksMetroState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  },
)

export const deleteMetroRecode = createAsyncThunk(
  "deleteMetroRecode",
  (_, { dispatch }) => {
    BaseApi.deleteRecodeMetro()
      .then((response) => {
        response.data?.state &&
          dispatch(setUpdateStoksMetroState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksMetroState(error.response.data.state))
      })
  },
)

export const uploadRecodeMetro = createAsyncThunk(
  "uploadRecodeMetro",
  async (file: File, { dispatch }) => {
    windowLock()

    try {
      const dataFromXslx = await readXlsxFile<RecodeMetroType>(file, {
        schema: metroShemaRecode,
      })
      const jsonData = dataFromXslx.rows

      BaseApi.uploadRecodeMetro(jsonData)
        .then((response) => {
          response.data?.state &&
            dispatch(setUpdateStoksMetroState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        })
        .catch((error) => {
          console.error(error)
          error?.response?.data?.state &&
            dispatch(setUpdateStoksMetroState(error.response.data.state))
        })
    } catch (error) {
      console.error("Error reading file:", error)
      NotificationService.showNotification({
        message: "Ошибка при чтении файла",
        level: "error",
      })
    }
  },
)
