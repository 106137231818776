import { ChangeEvent, useState, useEffect, FC } from "react"
import s from "./UpdateStoks.module.css"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Modal } from "../../features/Modal/Modal"
import { selectOrdersStore } from "../../app/reducers/ordersReducer"
import {
  deleteMetroRecode,
  metroUpdateStoks,
  selectUpdateStoksMetroStore,
  updateMetroState,
  uploadRecodeMetro,
} from "../../app/reducers/updateStoksMetro"
import { TipMetro } from "./components"
import { StatusInfo } from "../UpdateStoksIcon/StatusInfo/StatusInfo"

export const UpdateStoksMetro: FC = () => {
  const dispatch = useAppDispatch()
  const [selectedStorageId, setSelectedStorages] = useState("")
  const [isOpenModalUpdatePrices, setOpenModalUpdatePrices] = useState(false)
  const [updatePrices, setUpdatePrices] = useState(false)
  const [updateStocks, setUpdateStocks] = useState(false)

  useEffect(() => {
    dispatch(updateMetroState())
  }, [])

  const updateStoksStore = useAppSelector(selectUpdateStoksMetroStore)
  const ordersStore = useAppSelector(selectOrdersStore)
  const { lastStoksUpdated, lastPricesUpdated, status, isCurrentDataUploaded } =
    updateStoksStore.wildberries || {}
  const { storages } = ordersStore

  const handleUpdate = () => {
    if (
      (updatePrices || updateStocks) &&
      isCurrentDataUploaded &&
      selectedStorageId
    ) {
      setOpenModalUpdatePrices(true)
    }
  }

  return (
    <div className={s.updateStoksWrapper}>
      <h5>Обновление остатков и цен Метро</h5>
      {lastPricesUpdated && (
        <h6>
          Последнее обновление цен:{" "}
          {new Date(lastPricesUpdated).toLocaleString()}
        </h6>
      )}
      {lastStoksUpdated && (
        <h6>
          Последнее обновление остатков:{" "}
          {new Date(lastStoksUpdated).toLocaleString()}
        </h6>
      )}
      {status && (
        <h6>
          Статус: <StatusInfo status={status} />
        </h6>
      )}

      <div className={s.uploadFileWrap}>
        {isCurrentDataUploaded ? (
          <div>
            <button
              onClick={() => {
                dispatch(deleteMetroRecode())
              }}
            >
              удалить файл перекодировки
            </button>
          </div>
        ) : (
          <div>
            <label className={s.ktrnFileLabel} htmlFor={s.ktrnFileInp}>
              Загрузить файл перекодировки
            </label>
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const file = e.target.files?.[0]
                if (file) {
                  dispatch(uploadRecodeMetro(file))
                }
              }}
              type="file"
              accept=".xlsx"
              id={s.ktrnFileInp}
            />
          </div>
        )}
        <TipMetro />
      </div>

      <div className={s.dropdown}>
        <label htmlFor="storages-select">Склад для обновления</label>
        <select
          name="stock"
          id="storages-select"
          value={selectedStorageId}
          onChange={(e) => {
            setSelectedStorages(e.target.value)
          }}
        >
          <option value={""}>Выбрать склад</option>
          {storages.map((storage) => (
            <option value={storage.id} key={storage.name}>
              {storage.name}
            </option>
          ))}
        </select>
      </div>
      <div className={s.checkboxContainer}>
        <label>
          <input
            type="checkbox"
            checked={updatePrices}
            onChange={(e) => setUpdatePrices(e.target.checked)}
          />
          Обновить цены
        </label>
        <label>
          <input
            type="checkbox"
            checked={updateStocks}
            onChange={(e) => setUpdateStocks(e.target.checked)}
          />
          Обновить остатки
        </label>
      </div>
      <button
        className={s.updateButton}
        disabled={
          !isCurrentDataUploaded ||
          !selectedStorageId ||
          (!updatePrices && !updateStocks)
        }
        onClick={handleUpdate}
      >
        Обновить Метро
      </button>

      <Modal
        isOpen={isOpenModalUpdatePrices}
        header="Подтвердите действие. Обновление на Метро"
        content={`Обновить ${updatePrices ? "цены" : ""} ${
          updatePrices && updateStocks ? "и" : ""
        } ${updateStocks ? "остатки" : ""} для склада ${
          storages.find((el) => el.id === +selectedStorageId)?.name
        }`}
        onClose={() => setOpenModalUpdatePrices(false)}
        onOk={() => {
          selectedStorageId &&
            dispatch(
              metroUpdateStoks({
                selectedStorageId,
                updatePrices,
                updateStocks,
              }),
            )
          setOpenModalUpdatePrices(false)
        }}
      />
    </div>
  )
}
