import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  getOrdersState,
  getSuppliesWBWithOrders,
  selectOrdersStore,
  updateSupplies,
} from "../../app/reducers/ordersReducer"
import s from "./Supplies.module.css"
import BaseApi from "../../api/api"
import { Modal } from "../../features/Modal/Modal"
import { ModalOrderList } from "./ModalOrderList"
import { FormXlsxForOrderIcon } from "../FormXlsxForOrderIcon/FormXlsxForOrderIcon"
import { FormPodrOrder } from "../FormPodrOrder/FormPodrOrder"
import { FormKatrenOrder } from "../FormKatrenOrder/FormKatrenOrder"

export const Supplies = () => {
  useEffect(() => {
    dispatch(getOrdersState())
  }, [])
  const dispatch = useAppDispatch()
  const ordersStore = useAppSelector(selectOrdersStore)
  const { formOrders, supplies, lastDateUpdatedSupplies } = ordersStore
  const [selectedSupplyId, setSelectedSupplyId] = useState<string>("")
  return (
    <div>
      <div>
        <h5>Формирование файла поставок для заказа у дистрьебьютера</h5>
      </div>
      <div>
        <div>
          <div>
            <div>
              <select
                name="supplies"
                id="supplies-select"
                value={selectedSupplyId || ""}
                onChange={(e) => {
                  const supp = supplies.find((el) => el.id === e.target.value)
                  setSelectedSupplyId(e.target.value)
                  if (!supp?.orders) {
                    dispatch(getSuppliesWBWithOrders(e.target.value))
                  }
                }}
              >
                <option value={""}>Выбрать поставку</option>
                {supplies.map((supp) => (
                  <option value={supp.id} key={supp.name}>
                    {supp.name}
                  </option>
                ))}
              </select>
              <button
                className={s.updateSupplies}
                onClick={() => {
                  dispatch(updateSupplies()).then(() => setSelectedSupplyId(""))
                }}
              >
                Обновить поставки
                {lastDateUpdatedSupplies &&
                  ` (обновлено: ${lastDateUpdatedSupplies})`}
              </button>
            </div>
          </div>
          <div>
            <div>
              <h5>Сборочные задания поставки</h5>
              <div className={s.content}>
                <table>
                  <thead>
                    <tr>
                      <th>№ задания</th>
                      <th>Создано</th>
                      <th>Склад</th>
                      <th>Артикул продавца</th>
                      <th>Артикул WB</th>
                      <th>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(
                      (selectedSupplyId &&
                        supplies.find((el) => el.id === selectedSupplyId)
                          ?.orders) ||
                      []
                    ).map((el, i) => {
                      return (
                        <tr key={el.article + "-order-supp-" + i}>
                          <td>{el.id}</td>
                          <td>{new Date(el.createdAt).toLocaleString()}</td>
                          <td>{el.warehouseId}</td>
                          <td>{el.article}</td>
                          <td>{el.nmId}</td>
                          <td>{el.convertedPrice / 100}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className={s.buttonsWrap}>
                  <FormKatrenOrder
                    orders={
                      supplies.find((el) => el.id === selectedSupplyId)?.orders
                    }
                  />
                  <FormXlsxForOrderIcon
                    orders={
                      supplies.find((el) => el.id === selectedSupplyId)?.orders
                    }
                  />
                  <FormPodrOrder supplyId={selectedSupplyId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
