import { ChangeEvent, FC, useState } from "react"
import s from "./UpdateStoks.module.css"
import { useAppDispatch } from "../../app/hooks"
import { Modal } from "../../features/Modal/Modal"
import {
  deleteCurrentDataKatrenForWB,
  katrenUpdateStoksForWB,
  uploadCurrentDataKatrenForWB,
} from "../../app/reducers/updateStoksKatren"
import { Tip, TipType } from "../Tip/Tip"
import { STATUS } from "../../utils/const"
import { StatusInfo } from "../UpdateStoksIcon/StatusInfo/StatusInfo"
import { LogModal } from "../LogModal/LogModal"
import { getWbkatrnLogData } from "./helpers"

type WildberriesTabProps = {
  lastStoksUpdated: string
  lastPricesUpdated: string
  status?: STATUS
  isCurrentDataUploaded: boolean
  isDataFromKatrenUploaded: boolean
  storages: {
    name: string
    officeId: number
    id: number
  }[]
}

export const WildberriesTab: FC<WildberriesTabProps> = ({
  lastStoksUpdated,
  lastPricesUpdated,
  isCurrentDataUploaded,
  isDataFromKatrenUploaded,
  status,
  storages,
}) => {
  const dispatch = useAppDispatch()
  const [selectedStorageId, setSelectedStorages] = useState("")
  const [isOpenModalUpdatePrices, setOpenModalUpdatePrices] = useState(false)
  const [updatePrices, setUpdatePrices] = useState(false)
  const [updateStocks, setUpdateStocks] = useState(false)
  const [isOpenLogModal, setOpenLogModal] = useState(false)

  const handleUpdate = () => {
    if (
      (updatePrices || updateStocks) &&
      isCurrentDataUploaded &&
      isDataFromKatrenUploaded &&
      selectedStorageId
    ) {
      setOpenModalUpdatePrices(true)
    }
  }

  return (
    <div>
      <div className={s.uploadFileWrap}>
        {isCurrentDataUploaded ? (
          <div>
            <button
              onClick={() => {
                dispatch(deleteCurrentDataKatrenForWB())
              }}
            >
              удалить файл перекодировки
            </button>
          </div>
        ) : (
          <div>
            <label className={s.ktrnFileLabel} htmlFor={s.ktrnFileInp}>
              Загрузить файл перекодировки
            </label>
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const file = e.target.files?.[0]
                if (file) {
                  dispatch(uploadCurrentDataKatrenForWB(file))
                }
              }}
              type="file"
              accept=".xlsx"
              id={s.ktrnFileInp}
            />
          </div>
        )}
        <Tip
          content={
            <span>
              <b>Внимание!</b>
              <p>
                Файл перекодировки должен быть формата <b>XLSX</b> и содержать
                следующие <b>обязательные</b> колонки:
              </p>
              <ol className={s.list}>
                <li>
                  <b>GoodName</b> - название продукта c катрена
                </li>
                <li>
                  <b>GoodCode</b> - артикул продавца с WB
                </li>
                <li>
                  <b>nmId</b> - артикул WB
                </li>
                <li>
                  <b>sku</b> - баркод с WB
                </li>
                <li>
                  <b>count</b> - количество товаров продоваемых в карточке
                  (кратность)
                </li>
              </ol>
            </span>
          }
          type={TipType.WARNING}
        />
      </div>
      {lastPricesUpdated && (
        <h6>
          Последнее обновление цен:{" "}
          {new Date(lastPricesUpdated).toLocaleString()}
        </h6>
      )}
      {lastStoksUpdated && (
        <h6>
          Последнее обновление остатков:{" "}
          {new Date(lastStoksUpdated).toLocaleString()}
        </h6>
      )}
      {status && (
        <h6>
          статус: <StatusInfo status={status} />
        </h6>
      )}
      <div className={s.dropdown}>
        <label htmlFor="storages-select">Склад для обновления</label>
        <select
          name="stock"
          id="storages-select"
          value={selectedStorageId}
          onChange={(e) => {
            setSelectedStorages(e.target.value)
          }}
        >
          <option value={""}>Выбрать склад</option>
          {storages.map((storage) => (
            <option value={storage.id} key={storage.name}>
              {storage.name}
            </option>
          ))}
        </select>
      </div>
      <div className={s.checkboxContainer}>
        <label>
          <input
            type="checkbox"
            checked={updatePrices}
            onChange={(e) => setUpdatePrices(e.target.checked)}
          />
          Обновить цены
        </label>
        <label>
          <input
            type="checkbox"
            checked={updateStocks}
            onChange={(e) => setUpdateStocks(e.target.checked)}
          />
          Обновить остатки
        </label>
      </div>
      <div>
        <button
          className={s.updateButton}
          disabled={
            !isCurrentDataUploaded ||
            !isDataFromKatrenUploaded ||
            !selectedStorageId ||
            (!updatePrices && !updateStocks)
          }
          onClick={handleUpdate}
        >
          Обновить wildberries
        </button>
        <button onClick={() => setOpenLogModal(true)}>
          Посмотреть историю обновлений
        </button>
      </div>

      <Modal
        isOpen={isOpenModalUpdatePrices}
        header="Подтвердите действие. Обновление на WILDBERRIES"
        content={`Обновить ${updatePrices ? "цены" : ""} ${
          updatePrices && updateStocks ? "и" : ""
        } ${updateStocks ? "остатки" : ""} для склада ${
          storages.find((el) => +el.id === +selectedStorageId)?.name
        }`}
        onClose={() => setOpenModalUpdatePrices(false)}
        onOk={() => {
          selectedStorageId &&
            dispatch(
              katrenUpdateStoksForWB({
                selectedStorageId,
                updatePrices,
                updateStocks,
              }),
            )
          setOpenModalUpdatePrices(false)
        }}
      />
      <LogModal
        isOpen={isOpenLogModal}
        onClose={() => setOpenLogModal(false)}
        getData={getWbkatrnLogData}
      />
    </div>
  )
}
