import { getOrdersState } from "../../reducers/ordersReducer"
import { updateSettingsState } from "../../reducers/settingsReducer"
import { updateStoksICONState } from "../../reducers/updateStoksIcon"
import { updateKatrenStoksState } from "../../reducers/updateStoksKatren"
import { updateMetroState } from "../../reducers/updateStoksMetro"
import {
  getPodrOrders,
  updateStoksPodruzhkaState,
} from "../../reducers/updateStoksPodruzhka"
import { AppDispatch } from "../../store"
import { WSocketService } from "./WSocketService"

export const subscribeChangedThunk = () => (dispatch: AppDispatch) => {
  WSocketService.subscribe("changed", (messageData) => {
    const { key } = messageData || {}
    if (key) {
      switch (key) {
        case "settings":
          dispatch(updateSettingsState())
          break
        case "katrenUpdateStoksState":
          dispatch(updateKatrenStoksState())
          break
        case "iconUpdateStoksState":
          dispatch(updateStoksICONState())
          break
        case "podrUpdateStoksState":
          dispatch(updateStoksPodruzhkaState())
          break
        case "podruzhkaBasket":
          dispatch(getPodrOrders())
          break
        case "metroUpdateStoksState":
          dispatch(updateMetroState())
          break
        case "ordersStore":
          dispatch(getOrdersState())
          break
        default:
          console.log("unknown action key", key)
          break
      }
    }
  })
}
