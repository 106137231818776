import React, { FC, useEffect, useState } from "react"
import { Modal } from "../../features/Modal/Modal"
import { getMessage } from "../../msgs/msgs"

type LogModalProps = {
  isOpen: boolean
  getData: () => Promise<{ [key: string]: string }[]>
  onClose: () => void
}

export const LogModal: FC<LogModalProps> = (props) => {
  const { isOpen, onClose, getData } = props
  const [tableData, setTableData] = useState<{ [key: string]: string }[]>([])
  useEffect(() => {
    isOpen &&
      getData().then((data) => {
        setTableData(data)
      })
  }, [isOpen])
  return (
    <Modal
      isOpen={isOpen}
      header="История операций"
      content={<LogTable data={tableData} />}
      onClose={onClose}
      onOk={onClose}
      hideCancelBtn
    />
  )
}

type LogTableProps = {
  data?: { [key: string]: string | number }[]
}
const LogTable: FC<LogTableProps> = (props) => {
  const { data } = props
  if (!data?.length) {
    return <span>Нет данных для отображения</span>
  }

  return (
    <table>
      <thead>
        <tr>
          {Object.keys(data[0]).map((el) => {
            return <th>{getMessage(el)}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((el) => {
          return (
            <tr>
              {Object.keys(el).map((key) => {
                let value = el[key]
                if (key === "date") {
                  value = new Date(value).toLocaleString()
                }
                return <td>{value}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
