import { FC, useEffect, useState } from "react"
import s from "./UpdateStoks.module.css"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  deleteDataFromKatren,
  selectUpdateStoksKatrenStore,
  updateKatrenStoksState,
  uploadDataFromKatren,
} from "../../app/reducers/updateStoksKatren"
import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"
import { UploadMultipleFilesFromKatren } from "./UploadMultipleFilesFromKatren"
import { UploadFileFromKatren } from "./UploadFileFromKatren"
import { selectOrdersStore } from "../../app/reducers/ordersReducer"
import { WildberriesTab } from "./WbTabUpdateStoks"
import { OzonTabUpdateStoks } from "./OzonTabUpdateStoks"
import { selectActiveShops } from "../../app/reducers/settingsReducer"
import { useNavigateSubTab } from "../../utils/hooks/useNavigateTab"

export const UpdateStoksKatren: FC = () => {
  const dispatch = useAppDispatch()
  useState(false)
  useEffect(() => {
    dispatch(updateKatrenStoksState())
  }, [])
  const updateStoksStore = useAppSelector(selectUpdateStoksKatrenStore)
  const ordersStore = useAppSelector(selectOrdersStore)
  const { storages, ozonStorages } = ordersStore
  const {
    lastStoksUpdated,
    lastPricesUpdated,
    status,
    isCurrentDataUploaded,
    isDataFromKatrenUploaded,
    ozon,
  } = updateStoksStore
  const activeShops = useAppSelector(selectActiveShops)
  const [activeSubTab, navigate] = useNavigateSubTab()

  const shops = []
  activeShops.includes("KATREN") &&
    shops.push(
      <TabContent name={"wildberries"} ident={"wildberries"}>
        <WildberriesTab
          lastStoksUpdated={lastStoksUpdated}
          lastPricesUpdated={lastPricesUpdated}
          isCurrentDataUploaded={isCurrentDataUploaded}
          isDataFromKatrenUploaded={isDataFromKatrenUploaded}
          status={status}
          storages={storages}
        />
      </TabContent>,
    )
  activeShops.includes("KATREN_OZON") &&
    shops.push(
      <TabContent name={"ozon"} ident={"ozon"}>
        <OzonTabUpdateStoks
          lastStoksUpdated={ozon?.lastStoksUpdated ?? ""}
          lastPricesUpdated={ozon?.lastPricesUpdated ?? ""}
          isCurrentDataUploaded={ozon?.isCurrentDataUploaded ?? false}
          isDataFromKatrenUploaded={isDataFromKatrenUploaded}
          status={ozon?.status}
          storages={ozonStorages}
        />
      </TabContent>,
    )

  return (
    <div className={s.updateStoksWrapper}>
      <h5>Загрузка остатков и обновление цен</h5>
      <div>
        <div className={s.uploadFilesWrap}>
          <Tabs>
            <TabContent
              name={"Загрузить файл цен и остатков с Катрена"}
              ident={"oneFile"}
            >
              <UploadFileFromKatren
                deleteDataFromKatren={() => {
                  dispatch(deleteDataFromKatren())
                }}
                uploadDataFromKatren={(
                  data: { name: string; amount: number; price: number }[],
                ) => {
                  dispatch(uploadDataFromKatren(data))
                }}
                isDataFromKatrenUploaded={isDataFromKatrenUploaded}
              />
            </TabContent>
            <TabContent
              name="Загрузить несколько файлов цен и остатков с Катрена"
              ident={"multipleFiles"}
            >
              <UploadMultipleFilesFromKatren
                deleteDataFromKatren={() => {
                  dispatch(deleteDataFromKatren())
                }}
                uploadDataFromKatren={(
                  data: { name: string; amount: number; price: number }[],
                ) => {
                  dispatch(uploadDataFromKatren(data))
                }}
                isDataFromKatrenUploaded={isDataFromKatrenUploaded}
              />
            </TabContent>
          </Tabs>
        </div>
        <div>
          <Tabs activeTabIdent={activeSubTab} onChange={navigate}>
            {...shops}
          </Tabs>
        </div>
      </div>
    </div>
  )
}
